$(document).ready(function() {
    $(document).on('submit','.ajax-form',function(e){
        e.preventDefault();

        var data = $(this).serialize();
        var url = $(this).attr( 'action' );

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: function (data) {
                $('.main-code-wrapper').fadeOut(500,function() {
                    $('.main-code-wrapper').html(data).fadeIn(500);
                });

            },
            timeout: 15000,
            error: function(data){
                // Error...
                var errors = $.parseJSON(data.responseText);

                console.log(errors);

                $('.alerts-wrapper').html('<ul>').addClass('alert alert-danger');

                $.each(errors, function(index, value) {
                    $('.alerts-wrapper ul').append(
                        $('<li>').text(value)
                    );

                    return false;
                });

                $('html, body').animate({
                    scrollTop: $('.alerts-wrapper ul').offset().top
                }, 500);
            }
        });
    });

    $(document).on('click', '.ajax-create', function( e ) {
        e.preventDefault();

        var url = $(this).attr('data-url');
        var work_date = $(this).attr('data-work-date');

        if (url) {
            $.ajax({
                type: "GET",
                url: url,
                data: '',
                success: function (data) {
                    $('.main-code-wrapper').fadeOut(500,function() {
                        $('.main-code-wrapper').html(data).fadeIn(500,function() {
                            switchPackageFormFields($('.package-type').val());
                        });
                    });
                },
                timeout: 15000,
                error: function(data){
                    // Error...
                    console.log(data);
                }
            });
        }
    });

    $(document).on('click', '.ajax-edit', function( e ) {
        e.preventDefault();

        var id = $(this).attr('data-id');
        var url = $(this).attr('data-url');

        $.ajax({
            type: "GET",
            url: url,
            data: '',
            success: function (data) {
                $('.main-code-wrapper').fadeOut(500,function() {
                    $('.main-code-wrapper').html(data).fadeIn(500,function() {
                        switchPackageFormFields($('.package-type').val());
                    });
                });
            },
            timeout: 15000,
            error: function(data){
                // Error...
                console.log(data);
            }
        });
    });

    $('.alert').not('.alert-important').delay(5000).slideUp(300);

    $('.profile-resend').click(function(e) {
        e.preventDefault();

        $('.profile-resend').closest('.alert').hide();
        $('.profile-resend-loading').show();

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/ajax-resend-confirmation',
            data: {
            },
            success: function (data) {
                $('.profile-resend-loading').hide();

                if (data['status']) {
                    var popupHTML = '<div class="popup-fancy">\
                                <h1>Email confirmation sent!</h1>\
                                <div>\
                                We have sent to your email address an email with a link.<br>\
                                Plase click the link in order to confirm your email address.\
                                </div>\
                                </div>';
                    $.fancybox(popupHTML, {});
                } else {
                    if (data['error']) {
                        //
                    }
                }
            },
            timeout: 15000,
            error: function(data){
                $('.package-rep-submit-wrapper').show();
                $('.package-rep-loading').hide();
                $('.profile-resend-loading').hide();

                // Error...
                console.log(data);
            }
        });
    });

    $('.payment-details-payment-type').change(function() {
        if ($(this).val() == 'Paypal' || $(this).val() == 'Bitcoin') {
            $('.payment-details-not-bank').fadeIn();
            $('.payment-details-bank-transfer').fadeOut();
        } else if ($(this).val() == 'Bank Transfer') {
            $('.payment-details-bank-transfer').fadeIn();
            $('.payment-details-not-bank').fadeOut();
        } else {
            $('.payment-details-not-bank').fadeOut();
            $('.payment-details-bank-transfer').fadeOut();
        }

        $('.payment-details-not-bank,.payment-details-bank-transfer input').val('');
    });

    if ($('.payment-details-payment-type').val() == 'Paypal' || $('.payment-details-payment-type').val() == 'Bitcoin') {
        $('.payment-details-not-bank').fadeIn();
        $('.payment-details-bank-transfer').fadeOut();
    } else if ($('.payment-details-payment-type').val() == 'Bank Transfer') {
        $('.payment-details-bank-transfer').fadeIn();
        $('.payment-details-not-bank').fadeOut();
    }

    $('.app-delete').click(function() {
        var id = $(this).attr('data-id');

        var popupHTML = '<div class="popup-fancy">\
                                <h1>App deletion</h1>\
                                <div>\
                                Are you sure you would like to delete this app?<br><br>\
                                <button class="btn btn-lg main-button main-button-green app-delete-confirm" data-id="'+id+'">YES</button>\
                                </div>\
                                </div>';
        $.fancybox(popupHTML, {});
    });

    $(document).on('click', '.app-delete-confirm', function( e ) {
        parent.$.fancybox.close();

        var id = $(this).attr('data-id');

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/ajax-delete-app',
            data: {
                'id':id
            },
            success: function (data) {
                window.location = '/apps';
            },
            timeout: 15000,
            error: function(data){
                // Error...
                console.log(data);
            }
        });
    });

    $('.exc-delete').click(function() {
        var id = $(this).closest('.main-table-square').attr('data-id');

        var popupHTML = '<div class="popup-fancy">\
                                <h1>Exclusion deletion</h1>\
                                <div>\
                                Are you sure you would like to delete this exclusion?<br><br>\
                                <button class="btn btn-lg main-button main-button-green exc-delete-confirm" data-id="'+id+'">YES</button>\
                                </div>\
                                </div>';
        $.fancybox(popupHTML, {});
    });

    $(document).on('click', '.exc-delete-confirm', function( e ) {
        parent.$.fancybox.close();

        var id = $(this).attr('data-id');

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/ajax-delete-exclusion',
            data: {
                'id':id
            },
            success: function (data) {
                window.location = '/exclusions';
            },
            timeout: 15000,
            error: function(data){
                // Error...
                console.log(data);
            }
        });
    });

    $('.app-iframe').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>iFrame code</h1>\
                                <div style="padding: 40px;">\
                                    <pre style="max-width: 1000px; white-space:pre-wrap;"><code>&lt;iframe width="750" height="1400" src="https://www.kiwiwall.com/wall/'+$(this).attr('data-hash')+'/{sub_id}" frameborder="0" allowfullscreen&gt;&lt;/iframe&gt;</code>    </pre>\
                                    <br><strong>NOTE1: Do not forget to replace {sub_id} with your user ID.</strong>\
                                    <br><strong>NOTE2: Please contact us if you require to pass more than one variable. We support up to 5 variables.</strong>\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.app-secret').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>Secret code</h1>\
                                <div style="padding:40px;">\
                                    '+$(this).attr('data-secret')+'\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.offers-thumb').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>Thumb</h1>\
                                <div style="padding: 40px;">\
                                    <pre style="max-width: 1000px; white-space:pre-wrap;"><code>'+$(this).attr('data-thumb')+'</code>    </pre>\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.offers-instructions').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>Instructions</h1>\
                                <div style="padding: 40px;">\
                                    '+$(this).attr('data-instructions')+'\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.offers-link').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>Link</h1>\
                                <div style="padding: 40px;">\
                                    <pre style="max-width: 1000px; white-space:pre-wrap;"><code>'+$(this).attr('data-link')+'</code>    </pre>\
                                </div>\
                                 <br><strong>NOTE: Do not forget to replace {sub_id_1} with your user ID.</strong>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.app-postback-test').click(function() {
        var app_id = $(this).closest('.main-table-square').attr('data-id');

        var popupHTML = '<div class="popup-fancy">\
                            <h1>Test Postback</h1>\
                            <div class="postback-wrapper" style="padding:40px; text-align:left;">\
                                <div class="form-group">\
                                    <label for="postback-subid">SubID</label>\
                                    <input type="text" class="form-control postback-subid" name="postback-subid" placeholder="SubId">\
                                </div>\
                                \
                                <div class="form-group">\
                                    <label for="postback-amount">Amount</label>\
                                    <input type="text" class="form-control postback-amount" name="postback-amount" placeholder="Amount">\
                                </div>\
                                \
                                <div class="form-group">\
                                    <button class="main-button main-button-green postback-submit">Test Postback</button>\
                                </div>\
                                \
                                <input type="hidden" class="postback-appid" name="postback-appid" value="'+app_id+'">\
                            </div>\
                            <div class="postback-loading"><img src="https://www.kiwiwall.com/img/loading.gif"></div>\
                            <div class="postback-result"></div>\
                            <p><a href="/postback-documentation">Postback Documentation</a></p>\
                            </div>';
        $.fancybox(popupHTML, {
            'width':700,
            'height':500,
            'autoSize' : false});
    });

    $('.app-api').click(function() {
        var popupHTML = '<div class="popup-fancy">\
                            <h1>API Key</h1>\
                                <div style="padding:40px;">\
                                    '+$(this).attr('data-api')+'\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $(document).on('click', '.postback-submit', function( e ) {
        var app_id = $(this).closest('.postback-wrapper').find('.postback-appid').val();
        var sub_id = $(this).closest('.postback-wrapper').find('.postback-subid').val();
        var amount = $(this).closest('.postback-wrapper').find('.postback-amount').val();

        $('.postback-loading').show();
        $('.postback-result').removeClass('alert alert-danger alert-success').text('');

        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            type: "POST",
            url: '/ajax-test-postback',
            data: {
                'app_id':app_id,
                'sub_id':sub_id,
                'amount_in_currency':amount
            },
            success: function (data) {
                $('.postback-loading').hide();
                if (data['result']) {
                    if (data['result'] == '1') {
                        $('.postback-result').text('Your postback was sent successfully').removeClass().addClass('postback-result alert alert-success');
                    } else {
                        $('.postback-result').html('Your postback failed. Please make sure your script returns the number 1 for it to be successful:<br><br>' + data['used_url']).removeClass().addClass('postback-result alert alert-danger');
                    }
                } else {
                    if (data['error']) {
                        $('.postback-result').text(data['error']).removeClass().addClass('postback-result alert alert-danger');
                    }
                }
            },
            timeout: 15000,
            error: function(data){
                $('.postback-loading').hide();
                // Error...
                console.log(data);
            }
        });
    });

    $('.payments-not-bank').click(function() {
        var payment_id = $(this).attr('data-payment-id');

        var popupHTML = '<div class="popup-fancy">\
                            <h1>Payment Info</h1>\
                                <div style="padding: 40px;">\
                                    Payment ID: '+payment_id+'\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.payments-wire').click(function() {
        var account_name = $(this).attr('data-account-name');
        var swift = $(this).attr('data-swift');
        var iban = $(this).attr('data-iban');
        var account_number = $(this).attr('data-account-number');
        var bank_name = $(this).attr('data-bank-name');
        var bank_address = $(this).attr('data-bank-address');
        var transfer_details = $(this).attr('data-transfer-details');

        var popupHTML = '<div class="popup-fancy">\
                            <h1>Bank Transfer Info</h1>\
                                <div style="padding: 40px; text-align:left;">\
                                    <label>Account Name:</label> '+account_name+'<br>\
                                    <label>Swift:</label> '+swift+'<br>\
                                    <label>IBAN:</label> '+iban+'<br>\
                                    <label>Account Number:</label> '+account_number+'<br>\
                                    <label>Bank Name:</label> '+bank_name+'<br>\
                                    <label>Bank Address:</label> '+bank_address+'<br>\
                                    <label>Comments:</label> '+transfer_details+'<br>\
                                </div>\
                            </div>';
        $.fancybox(popupHTML, {});
    });

    $('.main-table-add-filter').click(function(e) {
        var filter = $('.main-table-filter').val();
        var filter_name = filter.replace(new RegExp(' ', 'g'), '-').toLowerCase();
        var filter_text = $('.main-table-filter-text').val();
        var exists = false;

        $('.main-table-filter-more').val('');

        if (filter && filter_text) {
            $('.main-table-filter-wrapper').each(function() {
                console.log($(this).find('input').attr('name'));

                if ($(this).find('input').attr('name') == filter_name) {
                    var popupHTML = '<div class="popup-fancy">\
                                <div>You already have this filter in place</div>\
                                </div>';
                    $.fancybox(popupHTML, {});

                    exists = true;
                }
            });

            if (!exists) {
                $('.main-table-all-filters').append(
                    $('<div>').addClass('main-table-filter-wrapper').append(
                        $('<label>').attr('for',filter_name).text(filter+':')
                    ).append(
                        $('<div>').addClass('main-table-filter-content').text(filter_text)
                    ).append(
                        $('<span>').addClass('fa fa-remove main-table-filter-remove')
                    ).append(
                        $('<input>').attr({'type':'hidden', 'name':filter_name}).val(filter_text)
                    )
                );

                $('.main-table-filter-button-wrapper').show();
            }
        }
    });

    $(document).on('click', '.main-table-filter-remove', function( e ) {
        $(this).closest('.main-table-filter-wrapper').remove();

        if (!$('.main-table-filter-wrapper input').length) {
            $('.main-table-filter-button-wrapper').hide();
        }
    });

    $('.main-table-filter').change(function() {
        $('.main-table-filter-text').val('');
    });

    $('.main-table-filter-go').click(function() {
        $('.main-table-actions').val('');
        $('form').submit();
    });

    $("#datepicker").datepicker({ dateFormat: 'dd-mm-yy' });
    $("#datepicker2").datepicker({ dateFormat: 'dd-mm-yy' });

    $('[data-toggle="tooltip"]').tooltip();
});
